import { Component, Signal, computed, inject } from '@angular/core';
import { AuthService } from '../../../../../frio-lib/src/lib/auth-module/services/auth.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
    selector: 'fa-filters',
    imports: [
        CommonModule,
        MatIconModule,
        MatListModule,
        // RouterLinkActive,
        // RouterLink,
    ],
    templateUrl: './dashboard.component.html',
    styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  public authService: AuthService = inject(AuthService);
}
