import { Component } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'fap-version',
    imports: [],
    templateUrl: './version.component.html',
    styleUrl: './version.component.scss'
})
export class VersionComponent {
  environmentName: string = environment.environmentName;
  version: string         = environment.version;
}
