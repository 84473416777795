import { Component, Inject, InjectionToken, Injector, effect } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AuthModule } from '../../../frio-lib/src/public-api';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { User } from '../../../frio-lib/src/lib/auth-module/models/user';
import { CommonModule } from '@angular/common';
import { HighlightDirective } from './directives/highlight.direcive';
import { TempComponent } from './temp/temp.component';
import { TempxDirective } from './tempx.directive';
import { ShowIfHasRoleDirective } from './directives/show-if-has-role.directive';
import { AuthService } from '../../../frio-lib/src/lib/auth-module/services/auth.service';
import { RoleKey } from '../../../frio-lib/src/lib/auth-module/models/role-key';
import { MatButtonModule } from '@angular/material/button';
import { trigger, transition, query, style, stagger, animate, state, group, animateChild } from '@angular/animations';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { environment } from '../environments/environment';
import { UserContextService } from '../../../frio-lib/src/lib/auth-module/services/user-context.service';

export const MENUSTATEKEY: string = "menu_state";

@Component({
    selector: 'app-root',
    imports: [
        CommonModule,
        RouterOutlet,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatListModule,
        MatSlideToggleModule,
        MatTooltipModule,
        AuthModule,
        RouterLinkActive,
        RouterLink,
        // HighlightDirective,
        // TempComponent,
        // TempxDirective,
        // ShowIfHasRoleDirective,
    ],
    animations: [
        trigger('openCloseMenu', [
            state('open', style({
                width: '300px'
            })),
            state('closed', style({
                width: '100px'
            })),
            transition('* => *', [
                group([
                    animate('0.25s'),
                    style({ width: '300px' }),
                    query('@*', animateChild(), { optional: true })
                ])
            ]),
        ]),
        trigger('openCloseNav', [
            state('open', style({
                width: '100%'
            })),
            state('closed', style({
                width: '3.5rem'
            })),
            transition('* => closed', [animate('0.25s')]),
            transition('* => open', [animate('0.25s')]),
        ]),
        trigger('openCloseMenuItem', [
            state('open', style({
                width: '*',
                'margin-left': '0.5rem',
                opacity: '1'
            })),
            state('closed', style({
                width: '0',
                'margin-left': '0',
                opacity: '0'
            })),
            transition('* => closed', [animate('0.25s')]),
            transition('* => open', [animate('0.25s')]),
        ]),
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent {
  RoleKey = RoleKey;

  appName: string         = environment.appName;
  environmentName: string = environment.environmentName;
  version: string         = environment.version;

  links: {
    name: string,
    link: string,
    isActive: boolean
  }[] = [
    {
      name: 'Pulpit',
      link: "/",
      isActive: false
    },
    // {
    //   name: 'Sklepy',
    //   link: "/sklepy",
    //   isActive: false
    // },
    {
      name: 'Alarmy',
      link: "/sklepy",
      isActive: false
    },
    // {
    //   name: 'Alarmy wg Reguł',
    //   link: "/sklepy",
    //   isActive: false
    // },
    {
      name: 'Ostrzeżenia',
      link: "/sklepy",
      isActive: false
    },
    {
      name: 'Ustawienia sklepów',
      link: "/sklepy",
      isActive: false
    }
  ];

  public menuCollapsed: boolean = false;

  user: User | null = null;

  constructor(
    public authService: AuthService,
    public userContextService: UserContextService
  ) {
    effect(() => {
      console.log(`User set to ${this.authService.user()}`);
      this.user = this.authService.user();
      this.init();
    });

    this.user = this.authService.user();

    this.init();
  }

  init() {
    this.menuCollapsed = this.userContextService.get(MENUSTATEKEY, Boolean, false);
  }

  toggleMenu() {
    this.menuCollapsed = !this.menuCollapsed;
    this.userContextService.set(MENUSTATEKEY, Boolean, this.menuCollapsed);
  }
}
