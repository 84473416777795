import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'fam-identity',
    imports: [
        RouterModule
    ],
    templateUrl: './identity.component.html',
    styleUrl: './identity.component.scss'
})
export class IdentityComponent {
  appName: string         = environment.appName;
}
